export const countries = [
    { value: 'BRASIL', label: 'Brasil' },
    { value: 'AFEGANISTAO', label: 'Afeganistão' },
    { value: 'AFRICA DO SUL', label: 'África do Sul' },
    { value: 'AKROTIRI', label: 'Akrotiri' },
    { value: 'ALBANIA', label: 'Albânia' },
    { value: 'ALEMANHA', label: 'Alemanha' },
    { value: 'ANDORRA', label: 'Andorra' },
    { value: 'ANGOLA', label: 'Angola' },
    { value: 'ANGUILA', label: 'Anguila' },
    { value: 'ANTARCTIDA', label: 'Antárctida' },
    { value: 'ANTIGUA E BARBUDA', label: 'Antígua e Barbuda' },
    { value: 'ARABIA SAUDITA', label: 'Arábia Saudita' },
    { value: 'ARCTIC OCEAN', label: 'Arctic Ocean' },
    { value: 'ARGELIA', label: 'Argélia' },
    { value: 'ARGENTINA', label: 'Argentina' },
    { value: 'ARMENIA', label: 'Arménia' },
    { value: 'ARUBA', label: 'Aruba' },
    { value: 'ASHMORE AND CARTIER ISLANDS', label: 'Ashmore and Cartier Islands' },
    { value: 'ATLANTIC OCEAN', label: 'Atlantic Ocean' },
    { value: 'AUSTRALIA', label: 'Austrália' },
    { value: 'AUSTRIA', label: 'Áustria' },
    { value: 'AZERBAIJAO', label: 'Azerbaijão' },
    { value: 'BAAMAS', label: 'Baamas' },
    { value: 'BANGLADECHE', label: 'Bangladeche' },
    { value: 'BARBADOS', label: 'Barbados' },
    { value: 'BAREM', label: 'Barém' },
    { value: 'BELGICA', label: 'Bélgica' },
    { value: 'BELIZE', label: 'Belize' },
    { value: 'BENIM', label: 'Benim' },
    { value: 'BERMUDAS', label: 'Bermudas' },
    { value: 'BIELORRUSSIA', label: 'Bielorrússia' },
    { value: 'BIRMANIA', label: 'Birmânia' },
    { value: 'BOLIVIA', label: 'Bolívia' },
    { value: 'BOSNIA E HERZEGOVINA', label: 'Bósnia e Herzegovina' },
    { value: 'BOTSUANA', label: 'Botsuana' },    ,
    { value: 'BRUNEI', label: 'Brunei' },
    { value: 'BULGARIA', label: 'Bulgária' },
    { value: 'BURQUINA FASO', label: 'Burquina Faso' },
    { value: 'BURUNDI', label: 'Burúndi' },
    { value: 'BUTAO', label: 'Butão' },
    { value: 'CABO VERDE', label: 'Cabo Verde' },
    { value: 'CAMAROES', label: 'Camarões' },
    { value: 'CAMBOJA', label: 'Camboja' },
    { value: 'CANADA', label: 'Canadá' },
    { value: 'CATAR', label: 'Catar' },
    { value: 'CAZAQUISTAO', label: 'Cazaquistão' },
    { value: 'CHADE', label: 'Chade' },
    { value: 'CHILE', label: 'Chile' },
    { value: 'CHINA', label: 'China' },
    { value: 'CHIPRE', label: 'Chipre' },
    { value: 'CLIPPERTON ISLAND', label: 'Clipperton Island' },
    { value: 'COLOMBIA', label: 'Colômbia' },
    { value: 'COMORES', label: 'Comores' },
    { value: 'CONGO-BRAZZAVILLE', label: 'Congo-Brazzaville' },
    { value: 'CONGO-KINSHASA', label: 'Congo-Kinshasa' },
    { value: 'CORAL SEA ISLANDS', label: 'Coral Sea Islands' },
    { value: 'COREIA DO NORTE', label: 'Coreia do Norte' },
    { value: 'COREIA DO SUL', label: 'Coreia do Sul' },
    { value: 'COSTA DO MARFIM', label: 'Costa do Marfim' },
    { value: 'COSTA RICA', label: 'Costa Rica' },
    { value: 'CROACIA', label: 'Croácia' },
    { value: 'CUBA', label: 'Cuba' },
    { value: 'CURACAO', label: 'Curacao' },
    { value: 'DHEKELIA', label: 'Dhekelia' },
    { value: 'DINAMARCA', label: 'Dinamarca' },
    { value: 'DOMINICA', label: 'Domínica' },
    { value: 'EGITO', label: 'Egito' },
    { value: 'EMIRADOS ARABES UNIDOS', label: 'Emidatos Árabes Unidos' },
    { value: 'EQUADOR', label: 'Equador' },
    { value: 'ERITREIA', label: 'Eritreia' },
    { value: 'ESLOVAQUIA', label: 'Eslováquia' },
    { value: 'ESLOVENIA', label: 'Eslovénia' },
    { value: 'ESPANHA', label: 'Espanha' },
    { value: 'ESTADOS UNIDOS', label: 'Estados Unidos' },
    { value: 'ESTONIA', label: 'Estónia' },
    { value: 'ETIOPIA', label: 'Estónia' },
    { value: 'FAROE', label: 'Faroé' },
    { value: 'FIJI', label: 'Fiji' },
    { value: 'FILIPINAS', label: 'Filipinas' },
    { value: 'FINLANDIA', label: 'Finlândia' },
    { value: 'FRANCA', label: 'França' },
    { value: 'GABAO', label: 'Gabão' },
    { value: 'GAMBIA', label: 'Gâmbia' },
    { value: 'GANA', label: 'Gana' },
    { value: 'GAZA STRIP', label: 'Gaza Strip' },
    { value: 'GEORGIA', label: 'Geórgia' },
    { value: 'GIBRALTAR', label: 'Gibraltar' },
    { value: 'GRANADA', label: 'Granada' },
    { value: 'GRECIA', label: 'Grécia' },
    { value: 'GROELANDIA', label: 'Groelândia' },
    { value: 'GUAME', label: 'Guame' },
    { value: 'GUATEMALA', label: 'Guatemala' },
    { value: 'GUERNSEY', label: 'Guernsey' },
    { value: 'GUIANA', label: 'Guiana' },
    { value: 'GUINE', label: 'Guiné' },
    { value: 'GUINE EQUATORIAL', label: 'Guiné Equatorial' },
    { value: 'GUINE-BISSAU', label: 'Guiné-Bissau' },
    { value: 'HAITI', label: 'Haiti' },
    { value: 'HONDURAS', label: 'Honduras' },
    { value: 'HONG KONG', label: 'Hong Kong' },
    { value: 'HUNGRIA', label: 'Hungria' },
    { value: 'IEMEN', label: 'Iémen' },
    { value: 'INDIA', label: 'Índia' },
    { value: 'INDONESIA', label: 'Indonésia' },
    { value: 'IRAO', label: 'Irão' },
    { value: 'IRAQUE', label: 'Iraque' },
    { value: 'IRLANDA', label: 'Irlanda' },
    { value: 'ISLANDIA', label: 'Islândia' },
    { value: 'ISRAEL', label: 'Israel' },
    { value: 'ITALIA', label: 'Itália' },
    { value: 'JAMAICA', label: 'Jamaica' },
    { value: 'JAN MAYEN', label: 'Jan Mayen' },
    { value: 'JAPAO', label: 'Japão' },
    { value: 'JERSEY', label: 'Jersey' },
    { value: 'JIBUTI', label: 'Jibuti' },
    { value: 'JORDANIA', label: 'Jordânia' },
    { value: 'KOSOVO', label: 'Kosovo' },
    { value: 'KUWAIT', label: 'Kuwait' },
    { value: 'LAOS', label: 'Laos' },
    { value: 'LESOTO', label: 'Lesoto' },
    { value: 'LETONIA', label: 'Letónia' },
    { value: 'LIBANO', label: 'Líbano' },
    { value: 'LIBERIA', label: 'Libéria' },
    { value: 'LIBIA', label: 'Líbia' },
    { value: 'LISTENSTAINE', label: 'Listenstaine' },
    { value: 'LITUANIA', label: 'Lituânia' },
    { value: 'LUXEMBURGO', label: 'Luxemburgo' },
    { value: 'MACAU', label: 'Macau' },
    { value: 'MACEDONIA', label: 'Macedónia' },
    { value: 'MADAGASCAR', label: 'Madagáscar' },
    { value: 'MALASIA', label: 'Malásia' },
    { value: 'MALAVI', label: 'Malávi' },
    { value: 'MALDIVAS', label: 'Maldivas' },
    { value: 'MALTA', label: 'Malta' },
    { value: 'MAN, ISLE OF', label: 'Man, Isle of' },
    { value: 'MARIANAS DO NORTE', label: 'Marianas do Norte' },
    { value: 'MARROCOS', label: 'Marrocos' },
    { value: 'MAURICIA', label: 'Maurícia' },
    { value: 'MAURITANIA', label: 'Mauritânia' },
    { value: 'MEXICO', label: 'México' },
    { value: 'MICRONESIA', label: 'Micronésia' },
    { value: 'MOCAMBIQUE', label: 'Moçambique' },
    { value: 'MOLDAVIA', label: 'Moldávia' },
    { value: 'MONACO', label: 'Mónaco' },
    { value: 'MONGOLIA', label: 'Mongólia' },
    { value: 'MONSERRATE', label: 'Monserrate' },
    { value: 'MONTENEGRO', label: 'Montenegro' },
    { value: 'MUNDO', label: 'Mundo' },
    { value: 'NAMIBIA', label: 'Namíbia' },
    { value: 'NAURU', label: 'Nauru' },
    { value: 'NAVASSA ISLAND', label: 'Navassa Island' },
    { value: 'NEPAL', label: 'Nepal' },
    { value: 'NICARAGUA', label: 'Nicarágua' },
    { value: 'NIGER', label: 'Níger' },
    { value: 'NIGERIA', label: 'Nigéria' },
    { value: 'NIUE', label: 'Niue' },
    { value: 'NORUEGA', label: 'Noruega' },
    { value: 'NOVA CALEDONIA', label: 'Nova Caledónia' },
    { value: 'NOVA ZELANDIA', label: 'Nova Zelândia' },
    { value: 'OMA', label: 'Omã' },
    { value: 'PACIFIC OCEAN', label: 'Pacific Ocean' },
    { value: 'PAISES BAIXOS', label: 'Países Baixos' },
    { value: 'PALAU', label: 'Palau' },
    { value: 'PANAMA', label: 'Panamá' },
    { value: 'PAPUA-NOVA GUINE', label: 'Papua-Nova Guiné' },
    { value: 'PAQUISTAO', label: 'Paquistão' },
    { value: 'PARACEL ISLANDS', label: 'Paracel Islands' },
    { value: 'PARAGUAI', label: 'Paraguai' },
    { value: 'PERU', label: 'Peru' },
    { value: 'PITCAIRN', label: 'Pitcairn' },
    { value: 'POLINESIA FRANCESA', label: 'Polinésia Francesa' },
    { value: 'POLONIA', label: 'Polónia' },
    { value: 'PORTO RICO', label: 'Porto Rico' },
    { value: 'PORTUGAL', label: 'Portugal' },
    { value: 'QUENIA', label: 'Quénia' },
    { value: 'QUIRGUIZISTAO', label: 'Quirguizistão' },
    { value: 'QUIRIBATI', label: 'Quiribáti' },
    { value: 'REINO UNIDO', label: 'Reino Unido' },
    { value: 'REPUBLICA CENTRO-AFRICANA', label: 'República Centro-Africana' },
    { value: 'REPUBLICA DOMINICANA', label: 'República Dominicana' },
    { value: 'ROMENIA', label: 'Roménia' },
    { value: 'RUANDA', label: 'Ruanda' },
    { value: 'RUSSIA', label: 'Rússia' },
    { value: 'SALVADOR', label: 'Salvador' },
    { value: 'SAMOA', label: 'Samoa' },
    { value: 'SAMOA AMERICANA', label: 'Samoa Americana' },
    { value: 'SANTA HELENA', label: 'Santa Helena' },
    { value: 'SANTA LUCIA', label: 'Santa Lúcia' },
    { value: 'SAO BARTOLOMEU', label: 'São Bartolomeu' },
    { value: 'SAO CRISTOVAO E NEVES', label: 'São Cristóvão e Neves' },
    { value: 'SAO MARINHO', label: 'São Marinho' },
    { value: 'SAO MARTINHO', label: 'São Martinho' },
    { value: 'SAO PEDRO E MIQUELON', label: 'São Pedro e Miquelon' },
    { value: 'SAO TOME E PRINCIPE', label: 'São Tomé e Príncipe' },
    { value: 'SAO VICENTE E GRANADINAS', label: 'São Vicente e Granadinas' },
    { value: 'SARA OCIDENTAL', label: 'Sara Ocidental' },
    { value: 'SEICHELES', label: 'Seicheles' },
    { value: 'SENEGAL', label: 'Senegal' },
    { value: 'SERRA LEOA', label: 'Serra Leoa' },
    { value: 'SERVIA', label: 'Sérvia' },
    { value: 'SINGAPURA', label: 'Singapura' },
    { value: 'SINT MAARTEN', label: 'Sint Maarten' },
    { value: 'SIRIA', label: 'Síria' },
    { value: 'SOMALIA', label: 'Somália' },
    { value: 'SPRATLY ISLANDS', label: 'Spratly Islands' },
    { value: 'SRI LANCA', label: 'Sri Lanca' },
    { value: 'SUAZILANDIA', label: 'Suazilândia' },
    { value: 'SUDAO', label: 'Sudão' },
    { value: 'SUDAO DO SUL', label: 'Sudão do Sul' },
    { value: 'SUECIA', label: 'Suécia' },
    { value: 'SUICA', label: 'Suíça' },
    { value: 'SURINAME', label: 'Suriname' },
    { value: 'TAILANDIA', label: 'Tailândia' },
    { value: 'TAIWAN', label: 'Taiwan' },
    { value: 'TAJIQUISTAO', label: 'Tajiquistão' },
    { value: 'TANZANIA', label: 'Tanzânia' },
    { value: 'TIMOR LESTE', label: 'Timor Leste' },
    { value: 'TOGO', label: 'Togo' },
    { value: 'TOKELAU', label: 'Tokelau' },
    { value: 'TONGA', label: 'Tonga' },
    { value: 'TRINDADE E TOBAGO', label: 'Trindade e Tobago' },
    { value: 'TUNISIA', label: 'Tunísia' },
    { value: 'TURQUEMENISTAO', label: 'Turquemenistão' },
    { value: 'TURQUIA', label: 'Turquia' },
    { value: 'TUVALU', label: 'Tuvalu' },
    { value: 'UCRANIA', label: 'Ucrânia' },
    { value: 'UGANDA', label: 'Uganda' },
    { value: 'URUGUAI', label: 'Uruguai' },
    { value: 'USBEQUISTAO', label: 'Usbequistão' },
    { value: 'VANUATU', label: 'Vanuatu' },
    { value: 'VATICANO', label: 'Vaticano' },
    { value: 'VENEZUELA', label: 'Venezuela' },
    { value: 'VIETNA', label: 'Vietnã' },
    { value: 'WAKE ISLAND', label: 'Wake Island' },
    { value: 'WALLIS E FUTUNA', label: 'Wallis e Futuna' },
    { value: 'ZAMBIA', label: 'Zâmbia' },
    { value: 'ZIMBABUE', label: 'Zimbabué' }
];
