import styled from "styled-components";
import CampoDigitacao from "../../components/CampoDigitacao";
import {useEffect, useState} from 'react';
import Botao from "../../components/Botao";
import { useNavigate } from "react-router-dom";
import logo from './logo1.png';
import usePost from "../../usePost";
import autenticaStore from "../../stores/autentica.store";
import http from "../../http";
import ReCAPTCHA from "react-google-recaptcha";

const Imagem = styled.img`
  width: 48px;
  height: 48px;
  padding: 1em 0;
`;

const Titulo = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--cinza-claro)
`;


const Formulario = styled.form`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;

   @media (min-width: 768px) {
    max-width: 70%;
  }

`;

const BotaoCustomizado = styled(Botao)`
  width: 50%;



`;

interface ILogin {
  login: string,
  senha: string
}

export default function Login() {
    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const {cadastrarDados, erro, sucesso, resposta} = usePost();
    const navigate = useNavigate();
    const [verified, setVerified] = useState(false);
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
      },
    };



    useEffect(() => {

      if (localStorage.getItem('authToken') != null && !autenticaStore.isTokenExpired()) {
          navigate('/home');
      }
    }, [navigate]);

    function onChange(value: any) {
      //console.log("Captcha value:", value);
      setVerified(true);
    }

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if(verified){
        const usuario: ILogin = {
          login: login,
          senha: senha
        }
  
        http.post('login', usuario, axiosConfig)
        .then(resposta => {          
            autenticaStore.login({login: login, token: resposta.data.token})
            resposta && navigate('/Home')
        })
        .catch(erro => {
          //console.log("erro" + erro)
            alert(erro)
          
        })
      }else{
        alert("reCAPTCHA não verificado")
      }
      


    }

    return (
        <>
        <Imagem src={logo} alt="Logo do RH sem Comp" />
        <Titulo>RH Integrado</Titulo>
        <Formulario onSubmit={handleLogin}>
            <CampoDigitacao required={true} tipo="login" label="Login" valor={login} placeholder="login" onChange={setLogin} />
            <CampoDigitacao required={true} tipo="password" label="Senha" valor={senha} placeholder="*******" onChange={setSenha} />
            <ReCAPTCHA sitekey="6Lf10zsqAAAAAFLQBmVCZKH81pE1LpzUOSBny_dl" onChange={onChange} />
            <BotaoCustomizado type="submit" >Entrar</BotaoCustomizado>
        </Formulario>
        
        
        </>  
    )
}
/* <Paragrafo>Esqueceu sua senha?</Paragrafo> */
/* <ParagrafoCadastro>Ainda não tem conta? <LinkCustomizado to="/cadastro">Faça seu cadastro!</LinkCustomizado></ParagrafoCadastro> */