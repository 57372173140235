import Container from "../../components/Container";
import Titulo from "../../components/Titulo";
import * as React from 'react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import autenticaStore from '../../stores/autentica.store';
import IBodyRequest from '../../types/IBodyRequest';
import http from '../../http';
import { useState, useEffect } from "react";
import Botao from '../../components/Botao';
import IVenda from '../../types/IVenda';
import ModalVenda from "./ModalVenda";
import IVendaTable from "../../types/IVendaTable";
import { formataMoeda } from "../../customFunctions/mascarasFormatacao";
import IClifor from "../../types/IClifor";
import ModalEdita from "./ModalEditar";

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'plataforma', headerName: 'Plataforma', width: 100 },
  { field: 'produto', headerName: 'Produto', width: 350 },
  { field: 'cliente', headerName: 'Cliente', width: 400 },
  { field: 'totalcomprador', headerName: 'Valor Comprador', width: 200 },
  { field: 'totalcomissao', headerName: 'Comissão', width: 150 },
  { field: 'datavenda', headerName: 'Data Venda', width: 150 },
  { field: 'statusvenda', headerName: 'Status', width: 130 },
];

export default function Vendas() {
  const [vendas, setVendas] = useState<IVenda[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [atulizarTabela, setAtulizarTabela] = useState(false);
  const [open, setOpen] = useState(false);
  const [editaOpen, setEditaOpen] = useState(false);
  const [tipo, setTipo] = useState("");
  const { usuario } = autenticaStore;
  const [newVendasTable, setVendasTable] = useState<IVendaTable[]>([]);

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${usuario.token}`,
    },
  };

  useEffect(() => {
    http.get<IBodyRequest<IVenda>>('venda?size=500', axiosConfig)
      .then(resposta => {
        setVendas(resposta.data.content);
      })
      .catch(erro => {
        alert(erro);
      });
  }, [atulizarTabela, open, editaOpen]);

  useEffect(() => {
    const processarVendas = async () => {
      const newVendasTable = await Promise.all(vendas.map(venda => processVenda(venda)));
      setVendasTable(newVendasTable);
    };

    processarVendas();
  }, [vendas]);

  const processVenda = async (venda: any): Promise<IVendaTable> => {
    let moeda = "";
    let totalBase = 0;
    let totalComprador = 0;
    let totalTaxas = 0;
    let totalComissao = 0;
    let nomeCliente = "";

    if (venda.pagamento.length >= 1) {
      moeda = venda.pagamento[0].moeda;
    }

    for (let i = 0; i < venda.pagamento.length; i++) {
      totalBase += venda.pagamento[i].valorbase;
      totalComprador += venda.pagamento[i].valorcomprador;
      totalTaxas += venda.pagamento[i].taxas;
      totalComissao += venda.pagamento[i].comissao;
    }

    try {
      const resposta = await http.get<IClifor[]>(`clifor/filter?attribute=cpfcnpj&value=${venda.documentocliente}`, axiosConfig);
      nomeCliente = resposta.data[0].nomecomercial + " " + resposta.data[0].nomesocial;
    } catch (erro) {
      alert("CLIFOR ERRO: " + erro);
    }

    return {
      id: venda.id,
      plataforma: venda.pagamento[0].meiopagamento,
      produto: venda.produto,
      cliente: nomeCliente,
      totalcomprador: formataMoeda(totalComprador, moeda),
      totalcomissao: formataMoeda(totalComissao, moeda),
      datavenda: venda.datavenda,
      statusvenda: venda.statusvenda
    };
  };

  const handleOpen = (tipo: string) => {
    if (tipo === "cadastra") {
      setTipo("cadastra");
      setOpen(true);
    } else if (tipo === "edita") {
      if (selectedRows.length == 0) {
        alert("nenhum registro selecionado!");
      } else if (selectedRows.length > 1) {
        alert("Favor selecionar um unico registro!");
      } else {
        setTipo("edita");
        setOpen(true);
      }
    }
  };

  const editaHandleOpen = () => {
    if(selectedRows.length == 0){
      alert("nenhum usuário selecionado!")
    }else if(selectedRows.length > 1){
      alert("Favor selecionar um unico usuário!")
    }else{
      setEditaOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const editaHandleClose =() => {
    setEditaOpen(false)    
  }

  const excluir = () => {
    if (selectedRows.length == 0) {
      alert("nenhum registro selecionado!");
    } else if (selectedRows.length > 1) {
      alert("Favor selecionar um unico registro!");
    } else {
      let id = selectedRows[0];
      http.delete(`venda/${id}`, axiosConfig)
        .then(resposta => {
          alert("VENDA EXCLUIDA COM SUCESSO!");
          setAtulizarTabela(!atulizarTabela);
        })
        .catch(erro => {
          alert(erro);
        });
    }
  };

  return (
    <Container>
      <Titulo>Vendas</Titulo>
      <div style={{ height: '100%', width: '100%' }}>
        <Botao onClick={() => handleOpen("cadastra")}>Cadastrar</Botao>
        <Botao onClick={() => editaHandleOpen()}>Visualizar</Botao>
        <Botao onClick={() => excluir()} style={{ backgroundColor: "#c50000", borderColor: "#c50000" }}>Excluir</Botao>
        <ModalVenda open={open} handleClose={handleClose} venda={vendas} id={selectedRows} tipo={tipo} />
        <ModalEdita editaOpen={editaOpen} editaHandleClose={editaHandleClose} editaVenda={vendas} id={selectedRows}/>
        <DataGrid style={{
          backgroundColor: "#3a3a3a",
          borderBottomColor: "#1b1b1b",
          borderTopColor: "#1b1b1b",
          color: "white",
          borderColor: '#1b1b1b',
        }}
          rows={newVendasTable}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(ids) => { setSelectedRows(ids);}}
        />
      </div>
    </Container>
  );
}