import * as React from 'react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import autenticaStore from '../../stores/autentica.store';
import IBodyRequest from '../../types/IBodyRequest';
import http from '../../http';
import { useState } from "react";
import Botao from '../../components/Botao';
import Container from "../../components/Container";
import Titulo from "../../components/Titulo";
import ModalProduto from './Modal';
import IProduto from '../../types/IProduto';





export default function Produto(){
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'origem', headerName: 'Origem', width: 200},
    { field: 'nome', headerName: 'Nome', width: 400 },
    { field: 'descricao', headerName: 'Descrição', width: 600 },
    { field: 'active', headerName: 'Ativo', width: 130 },
  ];
  
  
  
    const [produtos, setprodutos] = useState<IProduto[]>([]);
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const [atulizarTabela, setAtulizarTabela] = useState(false);
    const [open, setOpen ] = useState(false);
    const [editaOpen, setEditaOpen ] = useState(false);
    const [tipo, setTipo] = useState("");
    const {usuario} = autenticaStore;
  
    
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${usuario.token}`,
      },
    };
    
    React.useEffect(() => {
        http.get<IBodyRequest<IProduto>>('produtos', axiosConfig)
        .then(resposta => {
            setprodutos(resposta.data.content)
            //console.log("Ta atualizando Produto")
            //console.log(resposta.data.content)
        })
        .catch(erro => {
            alert(erro)
        })
  
    }, [atulizarTabela, open, editaOpen]);
  
    const handleOpen = (tipo: string) => {
      if(tipo === "cadastra"){
        setTipo("cadastra")
        setOpen(true)
      }else if(tipo === "edita"){       
        if(selectedRows.length == 0){
          alert("nenhum registro selecionado!")
        }else if(selectedRows.length > 1){
          alert("Favor selecionar um unico registro!")
        }else{
          setTipo("edita")
          setOpen(true)
        }
      }
    }

    const handleClose =() => {
      setOpen(false)    
    }

    const ativaDesativa = () => {
      
      if(selectedRows.length == 0){
        alert("nenhum registro selecionado!")
      }else if(selectedRows.length > 1){
        alert("Favor selecionar um unico registro!")
      }else{
        let id = selectedRows[0]
        const produto = produtos.find(item => item.id === id);
  
        if(produto?.active){
          http.put(`produtos/desativar/${id}`, null, axiosConfig)
          .then(resposta => {
              alert("produto desativado!")
              //console.log(resposta)
              setAtulizarTabela(!atulizarTabela)
          })
          .catch(erro => {
            alert(erro)
          })
        }else{
          http.put(`produtos/ativar/${id}`,null ,axiosConfig)
          .then(resposta => {
              alert("produto ativado!")
              //console.log(resposta)
              setAtulizarTabela(!atulizarTabela)
          })
          .catch(erro => {
            //console.log("deu ruim")
            alert(erro)
          })
        }
      }
    }
    const excluir = () => {
      
      if(selectedRows.length == 0){
        alert("nenhum registro selecionado!")
      }else if(selectedRows.length > 1){
        alert("Favor selecionar um unico registro!")
      }else{
        let id = selectedRows[0]
  
        http.delete(`produtos/${id}`, axiosConfig)
        .then(resposta => {
            alert("produto excluido!")
            //console.log(resposta)
            setAtulizarTabela(!atulizarTabela)
        })
        .catch(erro => {
          alert(erro)
        })   
      }
    }


  return (
    
    <Container >
          <Titulo>Produtos</Titulo>
          <div style={{ height: '100%', width: '100%'}}>
            <Botao onClick={() =>  handleOpen("cadastra")}>Cadastrar</Botao>
            <Botao onClick={() =>  handleOpen("edita")}>Editar/Precificar</Botao>
            <Botao onClick={() =>  ativaDesativa()}>Ativar/Desativar</Botao>
            <Botao onClick={() =>  excluir()} style={{backgroundColor: "#c50000", borderColor: "#c50000"}}>Excluir</Botao>
            <ModalProduto open={open} handleClose={handleClose} produtos={produtos} id={selectedRows} tipo={tipo}/>

            <DataGrid style={{ backgroundColor: "#3a3a3a",
                              borderBottomColor: "#1b1b1b",
                              borderTopColor: "#1b1b1b",
                              color: "white",
                              borderColor:'#1b1b1b',

                            }}
              rows={produtos}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20]}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(ids) => {setSelectedRows(ids)}}
            />
          </div>
    </Container>
  );
}
