import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.color || '#5c5c5c'}; 
  font-size: 15px;
  color: white;
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;
  margin: 0 10px 10px;

  > svg {
    margin: 0 20px;
  }

  &:hover {
    background-color: black;
  }
`;
//#5c5c5c
interface IItem{
  Text: string,
  href: string,
  color?: string
}

const SidebarItem = ({ Text, href, color }:IItem) => {
  const navigate = useNavigate();
  
  return (
    <Container onClick={() => navigate(`/${href}`)} color={color}>      
      {Text}
    </Container>
  )
}

export default SidebarItem