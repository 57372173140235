import { styled } from "styled-components"
import banner from "./banner.png"
import { Outlet } from "react-router-dom";

const ContainerPrincipal = styled.div`
    background-image: url(${banner});
    justify-content: center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Container = styled.div`
    border-radius: 10px;
    position: absolute;
    background-color: #646464bf;
    width: 90vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
    width: 50vw;
  }
`


export default function PaginaBaseFormulario(){
    return (

        <ContainerPrincipal>
             <Container>
                <Outlet />
            </Container>
        </ContainerPrincipal>

    )
}