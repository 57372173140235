import { useState, FormEvent } from "react";
import styled from "styled-components";
import banner from './banner_mentoria.png'
import Titulo from '../../components/Titulo';
import CampoDigitacao from "../../components/CampoDigitacao";
import Botao from "../../components/Botao";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PixIcon from '@mui/icons-material/Pix';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Modal, Switch, TextField } from '@mui/material';
import Pix from "@mui/icons-material/Pix";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from '@fortawesome/free-solid-svg-icons';

const ContainerPrincipal = styled.div`
    background: #E0D5C5;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;

`
const Container = styled.div`
    background-color: #EEEEEE;
    width: 50vw;
    min-height: 30%;
    border-radius: 5px;
`
const Banner = styled.section`
    position: relative;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding-top: 27.25%;
    width: 100%;
    background-color: black;
    background-image: url(${banner});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`

const BoxCustomizado = styled(Box)`

  padding: 10px;
  
`;

export default function Checkout() {

    const [teste, setTeste] = useState("");


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        
        //console.log("dados cadastro:")
        
            /* try {
                http.post(`clifor`,clienteFornecedor ,axiosConfig)
                .then(resposta => {
                    alert("usuario cadastrado com SUCESSO!")
                    handleClose()
                    limpaCampos()
                })
                .catch(erro => {            
                    alert("ERRO:" + erro)
                })   
            } catch (erro) {
                erro && alert('Erro ao cadastrar os dados')
            } */          
    }

    
    return (
        <ContainerPrincipal>
            <Container>
            <Banner/>
            <BoxCustomizado >
            <form onSubmit={handleSubmit}>
                    <Grid item xs={12} md={12}>
                        <h3>Identificação</h3>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Nome Completo"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Seu melhor email"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Confirme seu email"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="CPF"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Celular"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <h3>Endereço</h3>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="CEP"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Rua"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Número"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Complemento"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Bairro"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Cidade"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Estado"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <h3>Pagamento</h3>
                    </Grid>
                    <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Button variant="outlined" size="large" style={{paddingLeft: "10px", color: "#3d3d3d", borderColor: "#9c9c9cbe", marginRight: "20px"}}>
                            <CreditCardIcon fontSize="large" style={{margin: "8.5px"}}/>Cartão de Crédito
                        </Button>

                        <Button variant="outlined" size="large" style={{paddingLeft: "10px", color: "#3d3d3d", borderColor: "#9c9c9cbe", marginRight: "20px"}}>
                            <FontAwesomeIcon icon={faBarcode} size="2x" color="#3d3d3d" style={{margin: "11px"}}/> Boleto
                        </Button>

                        <Button variant="outlined" size="large" style={{paddingLeft: "10px", color: "#3d3d3d", borderColor: "#9c9c9cbe"}}>
                            <Pix fontSize="large" style={{margin: "8.5px"}}/>Pix
                        </Button>
                    </Grid>

                    
                    <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Número do Cartão"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Nome (Igual no cartão)"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Validade"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="CVV"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                        <TextField
                            //error
                            fullWidth
                            id="outlined-error-helper-text"
                            label="Quantidade de Parcelas"
                            
                            //helperText="Favor digitar o nome completo"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} style={{alignItems: "center"}}>
                        <Button variant="contained" size="large" >
                            Comprar agora
                        </Button>
                    </Grid>
                    </Grid>
                                       
                </form>
                </BoxCustomizado>
            </Container>
        </ContainerPrincipal>
    )
}

