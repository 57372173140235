import styled from "styled-components";
import Container from "../../components/Container";
import { useNavigate } from "react-router-dom";
import autenticaStore from "../../stores/autentica.store";
import http from '../../http';
import * as React from 'react';
import { useState } from "react";

const Titulo = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--cinza-claro)
`;

const Botao = styled.button`
  background-color: var(--azul-escuro);
  color: var(--branco);
  font-size: 20px;
  font-weight: 400;
  border: none;
  border-radius: 4px;
  padding: 16px 20px;
  margin: 16px;
  cursor: pointer;
`;



export default function PainelDeControle() {
    const navigate = useNavigate();
    const {usuario} = autenticaStore;
    const [papel, setPapel] = useState<string | null>('')
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${usuario.token}`,
      },
    };

    React.useEffect(() => {
      http.get(`usuarios/${localStorage.getItem('userID')}`, axiosConfig).then(resposta => {
        //console.log(resposta)
        setPapel(resposta.data.papel)
      })
      .catch(erro => {
          alert(erro)
      });
  
    }, [axiosConfig])

    return (
      <React.Fragment>
        {papel === 'ADMIN' && (
          <Container>
            <Titulo>Painel de Controle</Titulo>
            <Botao onClick={() => navigate("/usuarios")}>Usuários</Botao>
            <Botao onClick={() => navigate("/taxas")}>Taxas</Botao>
            <Botao onClick={() => navigate("/categoriasFinanceiro")}>Categorias Financeiro</Botao>
            <Botao onClick={() => navigate("/meta")}>Meta de Vendas</Botao>
          </Container>
        )}
      </React.Fragment>
    )
}
