import { Outlet } from "react-router-dom";
import Cabecalho from "../../components/Cabecalho";
import Rodape from "../../components/Rodape";
import styled from "styled-components";
import banner from "./banner.png"
import wallpaper from "./wallpaper.png"

const ContainerPrincipal = styled.div`
    background-image: url(${wallpaper});
    background-color: #1b1b1b;
    background-size: cover;
    width: 100%;
    height: 100vh;
    overflow: auto;

`

export default function PaginaBase(){
    return(
        <>
        <ContainerPrincipal>
        <Cabecalho />
        <main>
            <Outlet />
        </main>
        <Rodape />
        </ContainerPrincipal>
                
        </>
    )
}