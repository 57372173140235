import styled from "styled-components";

const Botao = styled.button`
background-color: var(--amarelo);
border-radius: 3px;
padding: 5px 16px;
color: var(--branco);
border: solid transparent;
margin-top: 1em;
margin-bottom: 1em;
font-weight: 700;
line-height: 19px;
margin-right: 2em;

&:hover {
    background-color: var(--amarelo-escuro); /* Cor de fundo ao passar o mouse */
  }

&:active{
    border: solid var(--amarelo);
}
`

export default Botao;