import React, { useState } from "react";
import { Grid, Modal } from '@mui/material';
import { Box } from "@mui/material";
import Titulo from "../../../components/Titulo";
import styled from "styled-components";
import Botao from "../../../components/Botao";
import autenticaStore from "../../../stores/autentica.store";
import { useForm } from "react-hook-form";
import http from "../../../http";
import { GridRowId } from "@mui/x-data-grid";
import IProduto from "../../../types/IProduto";
import Precificacao from "../../Precificacao";


const BoxCustomizado = styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-height: 90vh;
    overflow-y: auto;
    background-color: var(--cinza);
    border: none;
    border-radius: 5px;
    padding: 1em 5em;
`;

const Campo = styled.input`
    font-size: 15px;
    background: #f0f0f0;
    margin-top: 3px;
    margin-bottom: 25px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 10px;
`
const Container = styled.div`
text-align: left;
`
const BotaoCustomizado = styled(Botao)`
    width: 50%;
    display: block;
    margin: 0 auto;
`

const Rotulo = styled.label`
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--cinza-claro)
`

const CampoSelect = styled.select`
    background: #F0F0F0;
    margin-top: 3px;
    margin-bottom: 25px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 10px;
`
const SpanErro = styled.span`
    float: left;
    color: #bdbdbd;
    font-size: 14px;
    margin-top: -25px;
`

interface IProdutoPost {
    id: string,
    origem: string,
    nome: string,
    descricao: string,
    regras: string
}



export default function ModalProduto({ open, handleClose, produtos, id, tipo }: { open: boolean, handleClose: () => void, produtos: IProduto[], id: GridRowId[], tipo: string}) {
    const [idProduto, setIdProduto] = useState("");
    const {register, handleSubmit, reset, formState:{errors}, setValue} = useForm();
    /* const [precificacao, setPrecificacao] = useState<IPrecificacao[]>([]); */
    const [output, setOutput] = useState("");
    const [preencheu, setPreencheu] = useState(false);
    const [modal, setModal] = useState("produto");
    const {usuario} = autenticaStore;
    
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        },
    };
      
    if(open && !preencheu && tipo === 'edita'){
        const prod = produtos.find(item => item.id === id[0]);
        setIdProduto(prod?.id ? prod.id.toString() : "")
        setValue('origem', prod?.origem ? prod.origem : "")
        setValue('nome', prod?.nome ? prod.nome : "")
        setValue('descricao', prod?.descricao ? prod.descricao : "")
        setValue('regras', prod?.regras ? prod.regras : "")
        setPreencheu(true)
    }else if(open && !preencheu && tipo === 'cadastra'){
        reset()
        setPreencheu(true)
    }

    if(!open && preencheu){
        reset()
        setPreencheu(false)
    } 

    function postProduto(data: any){
        //setOutput(JSON.stringify(data, null, 2));
        //console.log(data)
        //console.log(JSON.stringify(data).indexOf("descricao"))
        if(JSON.stringify(data).indexOf("descricao") !== -1){
            const produto: IProdutoPost = {
                id: idProduto,
                origem: data.origem,
                nome: data.nome,
                descricao: data.descricao,
                regras: data.regras  
            }
            //setOutput(JSON.stringify(produto, null, 2));
            if(tipo === 'cadastra'){         
        
                http.post(`produtos`,produto ,axiosConfig)
                .then(resposta => {
                    alert("registro cadastrado com SUCESSO!")
                    reset()
                    handleClose()
                })
                .catch(erro => {            
                    alert("ERRO:" + erro)
                })  
            }else{
                http.put("produtos",produto ,axiosConfig)
                .then(resposta => {
                    alert("Alteração efetuada com sucesso!")
                    reset()
                    handleClose()
                })
                .catch(erro => {            
                    alert("ERRO:" + erro)
                })   
            }
        }
         
    } 

    const Close =() => {
        reset()
        handleClose()   
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => Close()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <BoxCustomizado style={{backgroundColor: `${tipo === 'cadastra' ? "#6B6E71" : "#1b1b1b"}`}}>
                    <form onSubmit={handleSubmit(postProduto)}>
                        <Titulo>{`${tipo === 'cadastra' ? "Cadastrar" : "Editar"} Produto`}</Titulo>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                                <Rotulo>Origem</Rotulo>
                                <Campo type="text" placeholder="" maxLength={100} {...register('origem', {required: "Campo obrigatório"})} />
                                {errors.origem && typeof errors.origem.message === 'string' && <SpanErro>{errors.origem.message}</SpanErro>}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Rotulo>Nome</Rotulo>
                                <Campo type="text" placeholder="" maxLength={100} {...register('nome', {required: "Campo obrigatório"})} />
                                {errors.nome && typeof errors.nome.message === 'string' && <SpanErro>{errors.nome.message}</SpanErro>}
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Rotulo>Descrição</Rotulo>
                                <Campo type="text" placeholder="" maxLength={100} {...register('descricao', {required: "Campo obrigatório"})} />
                                {errors.descricao && typeof errors.descricao.message === 'string' && <SpanErro>{errors.descricao.message}</SpanErro>}
                            </Grid>
                            <Grid item xs={12} md={12} style={{marginTop: "-30px"}}>
                                <Rotulo>Regras (Separar com ";") A regra deve ter seu valor cadastrado na tabela de taxas para ser calculada corretamente</Rotulo>
                                <Campo type="text" placeholder="" maxLength={100} {...register('regras')} />
                                {errors.regras && typeof errors.regras.message === 'string' && <SpanErro>{errors.regras.message}</SpanErro>}
                            </Grid>
                            <Grid item xs={12} md={2} > 
                                <Rotulo>&nbsp;</Rotulo>
                            <BotaoCustomizado style={{
                                        marginBottom: "40px", 
                                        marginTop: "-44px", 
                                        marginLeft: "0px"}}>
                                            {`${tipo === 'cadastra' ? "Cadastrar" : "Salvar"}`}</BotaoCustomizado>
                            </Grid>
                        </Grid>                
                        {/* <pre>{output}</pre> */}
                    </form>
                    {tipo === 'cadastra' ? "" : <Precificacao idProd={idProduto} />}  
                </BoxCustomizado>
            </Modal >
        </>
    );
}