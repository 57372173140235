import * as React from 'react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import autenticaStore from '../../stores/autentica.store';
import http from '../../http';
import { useState } from "react";
import Container from "../../components/Container";
import Titulo from "../../components/Titulo";
import styled from "styled-components";
import Select from 'react-select';
import IPagamentoNF from '../../types/IPagamentoNF';

const SelectCustomizado = styled(Select)`
    background: #F0F0F0;
    margin-top: 3px;
    margin-bottom: 25px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
`

const Rotulo = styled.label`
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--cinza-claro)
`

export default function PainelNF(){
  const columns: GridColDef[] = [
  { field: 'pagamentoId', headerName: 'Pagamento', width: 100 },
  { field: 'vendaId', headerName: 'Venda', width: 80 },
  { field: 'produto', headerName: 'Produto', width: 300 },
  { field: 'meiopagamento', headerName: 'Meio de Pagamento', width: 150 },
  { field: 'tipopagamento', headerName: 'Tipo de Pagamento', width: 200 },
  { field: 'moeda', headerName: 'Moeda', width: 100 },
  { field: 'valorcomprador', headerName: 'Valor Comprador', width: 130 },
  { field: 'parcelas', headerName: 'Parcelas', width: 80 },
  { field: 'valorbase', headerName: 'Valor Base', width: 150 },
  { field: 'taxas', headerName: 'Taxas', width: 150 },
  { field: 'comissao', headerName: 'Comissão', width: 150 },
  { field: 'datapagamento', headerName: 'Data de Pagamento', width: 150 },
  { field: 'nomecomercial', headerName: 'Nome', width: 150 },
  { field: 'nomesocial', headerName: 'Sobrenome', width: 250 },
  { field: 'datavenda', headerName: 'Data da Venda', width: 150 },
  { field: 'documentocliente', headerName: 'Documento do Cliente', width: 200 },
  { field: 'cpfcnpj', headerName: 'CPF', width: 150 },
  { field: 'email', headerName: 'Email', width: 350 },
  { field: 'telefone', headerName: 'Telefone', width: 150 },
  { field: 'pais', headerName: 'País', width: 150 },
  { field: 'cep', headerName: 'CEP', width: 150 },
  { field: 'uf', headerName: 'UF', width: 100 },
  { field: 'cidade', headerName: 'Cidade', width: 200 },
  { field: 'bairro', headerName: 'Bairro', width: 200 },
  { field: 'logradouro', headerName: 'Logradouro', width: 400 },
  { field: 'complemento', headerName: 'Complemento', width: 150 },
  { field: 'numero', headerName: 'Número', width: 100 },
  { field: 'cupom', headerName: 'Cupom', width: 150 },
  { field: 'statusvenda', headerName: 'Status da Venda', width: 150 }
  
  ];
  
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const {usuario} = autenticaStore;
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [pagamentos, setPagamentos] = useState<IPagamentoNF[]>([])
  
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${usuario.token}`,
    },
  };

  React.useEffect(() => {
    const firstDay = new Date(year, month - 1, 1);
    const lastDay = new Date(year, month, 0);

    const formattedFirstDay = firstDay.toISOString().split('T')[0];
    const formattedLastDay = lastDay.toISOString().split('T')[0];

    http.get<IPagamentoNF[]>(`venda/filtrarPorData?startDate=${formattedFirstDay}&endDate=${formattedLastDay}`, axiosConfig)
    .then(resposta => {   

      const dadosComId = resposta.data.map(dado => ({ ...dado, id: dado.pagamentoId }));
      setPagamentos(dadosComId)    
        
      })
      .catch(erro => {
        alert(erro) 
      })      
    
  }, [year, month]);



  const handleYearChange = (option: any) => {
    setYear(option.value);     

  };

  const handleMonthChange = (option: any) => {
    setMonth(option.value);  

  };   
  
   

  return (
    <Container>
          <Titulo>Painel de NF</Titulo>
          <Grid container spacing={2} >
           
            <Grid item xs={6} md={1}>
                <Rotulo>Ano:</Rotulo>
                <SelectCustomizado
                value={{ value: year, label: year }}
                onChange={(option) => handleYearChange(option)}
                options={Array.from({ length: 10 }, (_, i) => ({
                    value: new Date().getFullYear() - i,
                    label: new Date().getFullYear() - i,
                }))}
                />      
            </Grid>
            <Grid item xs={6} md={1}>
                <Rotulo>Mês:</Rotulo>
                <SelectCustomizado
                    value={{ value: month, label: month }}
                    onChange={(option) => handleMonthChange(option)}
                    options={Array.from({ length: 12 }, (_, i) => ({
                    value: i + 1,
                    label: i + 1,
                    }))}
                />
            </Grid>       
              
            
          </Grid>
          
          <div style={{ height: '100%', width: '100%'}}>
         

            <DataGrid style={{ backgroundColor: "rgb(33 34 34)",
                              borderBottomColor: "#1b1b1b",
                              borderTopColor: "#1b1b1b",
                              color: "white",
                              borderColor:'#1b1b1b',

                            }}
              rows={pagamentos}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              pageSizeOptions={[50, 100]}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(ids) => {setSelectedRows(ids)}}
            />
          </div>
              
          
    </Container>
  );
}
