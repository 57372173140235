import styled from "styled-components";

const Container = styled.section`
/* background-color: #1b1b1b; */
margin: 0em;
margin-top: 38px;
border-radius: 3px;
padding: 1em;
height: 100%;
margin-bottom: 160px;
`

export default Container;