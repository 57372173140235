import whatsapp from './assets/whatsapp.png';
import instagram from './assets/instagram.png';
import google from './assets/google.png';
import facebook from './assets/facebook.png';
import styled from 'styled-components';

const RodapeEstilizado = styled.footer`
height: 25px;
color: white;
padding: 0.5px;
background-color: black;
text-align: center;
position: fixed;
width: 100%;
z-index: 1;
bottom: 0;
right: 0;
`

const ListaEstilizada = styled.ul`
display: flex;
justify-content: space-around;
width: 10%;
margin: 1em auto;
`

const ItemEstilizado = styled.li`
list-style-type: none;
`

const Paragrafo = styled.p`
margin: 5px;
font-size: 10px;
`


function Rodape() {
    return (
        <RodapeEstilizado>
{/*             <ListaEstilizada>
                <ItemEstilizado>
                    <a href="#">
                        <img src={facebook} alt="logo do facebook" />
                    </a>
                </ItemEstilizado>
                <ItemEstilizado>
                    <a href="#">
                        <img src={whatsapp} alt="logo do whatsapp" />
                    </a>
                </ItemEstilizado>
                <ItemEstilizado>
                    <a href="#">
                        <img src={google} alt="logo do google" />
                    </a>
                </ItemEstilizado>
                <ItemEstilizado>
                    <a href="#">
                        <img src={instagram} alt="logo do instagram" />
                    </a>
                </ItemEstilizado>
            </ListaEstilizada> */}
            <Paragrafo>2024 © Desenvolvido por Bella Pertence LTDA.</Paragrafo>
        </RodapeEstilizado>
    )
}


export default Rodape;