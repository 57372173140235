import axios from "axios";

// Verifica se está no ambiente de produção ou desenvolvimento
const isProduction = window.location.hostname !== 'localhost';

// Define a URL base da API com base no ambiente
const baseURL = isProduction ? 'https://api.rhintegrado.com.br/' : 'http://localhost:8080/';

const http = axios.create({
    baseURL: baseURL
});

export default http;