import { makeObservable, observable, action } from "mobx";
import http from "../http";

interface ILogin {
    login: string,
    token: string
}

class AutenticaStore {
    estaAutenticado = false;
    usuario: ILogin = {login: "", token: ""};

    constructor() {
        makeObservable(this, {
            estaAutenticado: observable,
            usuario: observable,
            login: action,
            logout: action
        })

        // Inicialização a partir do localStorage
        const storedToken = localStorage.getItem('authToken');
        const storedUser = localStorage.getItem('user');

        if (storedToken && storedUser) {
        this.estaAutenticado = true;
        this.usuario = { login: storedUser, token: storedToken };
        }
    }

    isTokenExpired() {
        const loginTime = localStorage.getItem('loginTime');
        if (loginTime) {
          const currentTime = Date.now();
          const loginTimeMillis = parseInt(loginTime, 10);
    
          // Verifica se já passaram 3 horas (3 horas * 60 minutos * 60 segundos * 1000 milissegundos)
          return currentTime - loginTimeMillis >= 3 * 60 * 60 * 1000;
        }
        return false;
      }


    login({login, token} : ILogin) {

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        this.estaAutenticado = true;
        this.usuario = {login, token};
        http.get(`usuarios/login/${login}`, axiosConfig)
        .then(resposta => {          
            localStorage.setItem('userID', resposta.data.id);
            localStorage.setItem('authToken', token);
            localStorage.setItem('user', login);
            localStorage.setItem('loginTime', Date.now().toString());
            window.location.reload()
        })
        .catch(erro => {
          //console.log("erro" + erro)
            alert(erro + " erro ao buscar dados do usuario")
          
        })

        
    }

    logout() {
        this.estaAutenticado = false;
        this.usuario = {login: "", token: ""}
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        localStorage.removeItem('loginTime');
        localStorage.removeItem('userID');
        window.location.reload()
        
    }
}

const autenticaStore = new AutenticaStore();

export default autenticaStore;

// Em algum ponto de sua aplicação (por exemplo, no componente principal)
if (autenticaStore.isTokenExpired()) {
    // Limpa o localStorage
    autenticaStore.logout();
    alert("Sua sessão expirou. Por favor, faça login novamente.");
  }