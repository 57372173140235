import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Titulo from "../../components/Titulo";
import styled from "styled-components";
import CampoDigitacao from "../../components/CampoDigitacao";
import Botao from "../../components/Botao";
import autenticaStore from "../../stores/autentica.store";
import http from "../../http";

const BoxCustomizado = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  max-height: 90vh;
  overflow-y: auto;
  background-color: var(--cinza);
  border: none;
  border-radius: 5px;
  padding: 1em 5em;
`;

const Container = styled.div`
text-align: left;
`
const BotaoCustomizado = styled(Botao)`
    width: 50%;
    display: block;
    margin: 0 auto;
`
interface IUsuarioPut {
    id: string,
    login: string,
    email: string,
    password: string | null,
    nome: string
}

export default function MeuPerfil() {
    const [idUser, setIdUser] = useState("");
    const [login, setLogin] = useState("");
    const [email, setEmail] = useState("");
    const [nome, setNome] = useState("");
    const [password, setPassword] = useState("");
    const [senhaVerificada, setSenhaVerificada] = useState("");
    const {usuario} = autenticaStore;
    const [erro, setErro] = useState(false);

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        },
    };

    useEffect(() => {
        //console.log("useEffect!")
        if (localStorage.getItem('userID') != null ) {
            http.get(`usuarios/${localStorage.getItem('userID')}`, axiosConfig)
            .then(resposta => {          
                setIdUser(resposta.data.id)
                setLogin(resposta.data.login)
                setEmail(resposta.data.email)
                setNome(resposta.data.nome)            
            })
            .catch(erro => {
            //console.log("erro" + erro)
                alert("ERRO: "+ erro )
            
            })
        }
    }, [erro]);

    const handleSubmit = () => {
        if(password != senhaVerificada){
            alert("As senhas não conferem!")
            return
        }
        if(password.length < 8){
            alert("A senha deve ter no mínimo 8 caracteres!")
            return
        }
        const user: IUsuarioPut = {
            id: idUser,
            login: login,
            email: email,
            password: password == "" ? null : password,
            nome: nome
        }
        //console.log("chamada")
        http.put("usuarios",user ,axiosConfig)
        .then(resposta => {       
            console.log('agora vai')  
            alert("Alteração efetuada com sucesso!")
            console.log('teste')
            console.log(resposta.data)
            /* setLogin(resposta.data.login)
            setEmail(resposta.data.email)
            setNome(resposta.data.nome)     */        
            
        })
        .catch(erro => {            
            alert("ERRO:" + erro)
            setErro(!erro)
        })     
    }

    return (
      <Container>
      <BoxCustomizado>
                    <Titulo>Meu Perfil:</Titulo>
                    <form onSubmit={handleSubmit}>
                        <Container>
                            <CampoDigitacao required={true} tipo="text" label="Login" valor={login} placeholder="Digite seu login" onChange={setLogin} />
                            <CampoDigitacao required={true} tipo="text" label="Nome" valor={nome} placeholder="Digite seu nome" onChange={setNome} />
                            <CampoDigitacao required={true} tipo="email" label="Email" valor={email} placeholder="Digite seu email" onChange={setEmail} />
                            <CampoDigitacao required={false} tipo="password" label="Alterar senha" valor={password} placeholder="******" onChange={setPassword} />
                            <CampoDigitacao required={false} tipo="password" label="Repita a nova senha" valor={senhaVerificada} placeholder="******" onChange={setSenhaVerificada} />                                                  
                        </Container>
                        <br></br>
                        <BotaoCustomizado>Atualizar</BotaoCustomizado>
                        <br></br>
                    </form>
                </BoxCustomizado>
      </Container>
    )
}
